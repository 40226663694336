body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e0c0d !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper {
  max-height: 800;
  max-width: 400;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: #fff;
}

.swiper-slide:nth-child(2n) {
  background-color: #fff;
}

.swiper-slide:nth-child(3n) {
  background-color: #fff;
}

.swiper-slide:nth-child(4n) {
  background-color:#fff;
}

.swiper-slide:nth-child(5n) {
  background-color: #fff;
}

.swiper-slide:nth-child(6n) {
  background-color: #fff;
}

.swiper-slide:nth-child(7n) {
  background-color: #fff;
}

.swiper-slide:nth-child(8n) {
  background-color: rgb(0, 68, 255);
}

.swiper-slide:nth-child(9n) {
  background-color: rgb(218, 12, 218);
}

.swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
}