  .container.MuiBox-root  {
    padding-top: 80px;
    background: linear-gradient(180deg, #14171f, #39435c, #0e0c0d);
    min-height: 100vh;
    color: white;
    display: flex; 
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    overflow: hidden;
  }
  
  .content.MuiGrid-root {
    align-items: center;
    max-width: 1200px;
    padding: 40px;
  }
  .section.MuiGrid-root {
    margin-top: 60px;
  }
  .smaller-section.MuiGrid-root {
   width: 75%;
   max-width: 300px;
  }

  .story.MuiTypography-root {
    position: absolute;
    background-color: transparent;
    z-index: 3;
    font-size: 1.2rem;
    font-weight: 600;
    bottom: 0;
    padding: 50px 0px 8px 0px;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: linear-gradient(180deg,transparent, rgba(0,0,0,1.0))
  }
  
  .textContainer.MuiGrid-root  {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    flex-direction: column;
  }

  .textContainerRelative.MuiGrid-root  {
    margin-top: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .title.MuiTypography-root {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
    max-width: 600px;
  }

  .primary {
    color: #D8A363
  }
  
  .description.MuiTypography-root {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #ccc;
    max-width: 600px;
    text-align: left;
    margin-bottom: 20px;
  }

  .centered.MuiTypography-root  {
    text-align: center;
    max-width: 600px;
  }
  
  .buttonContainer.MuiGrid-root {
    align-items: center;
    justify-content: center;
    display: flex;
}
  
  .iosButton.MuiButton-root {
    background-color: #500087;
    color: white;
    text-transform: none;
    width: 100%;
    font-size: 1.2rem;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    font-weight: 700;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }

  .iosButton.MuiButton-root:hover {
    background-color: #7c00c7; /* Lighter shade of purple on hover */
    color: black; /* Black text color on hover */
  }

  .buttonPrimary.MuiButton-root {
    background-color: #D8A363;
     color: black;
  }

  .buttonPrimary.MuiButton-root:hover  {
    background-color: #FF9F32; 
    color: black;
  }
  
  .androidButton.MuiButton-root {
    color: white ;
    font-size: 1.2rem;
    border: 1px solid white;
    text-transform: none;
    width: 100%;
    border-radius: 10px;
    max-width: 600px;
    font-weight: 700;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }

  .androidButton.MuiButton-root:hover {
    background-color: white; /* White background on hover */
    color: black; /* Black text color on hover */
    border-color: black; /* Black border on hover */
  }
  
  .imageContainer.MuiGrid-root {
    justify-content: center;
  }

  .slideContainer.MuiGrid-root {
    justify-content: center;
    margin-top: 20px;
  }
  
  .image {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  }

  .no-shadow {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0);
  }

  .swoosh {
    margin-top: 80px;
    width: 100vw;
    height: auto;
    left: 0
  }

  .screenShotContainer.MuiBox-root {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: 32px;
    margin: 8px; /* Set margin on top and bottom only */
  }

  .screenShot {
    width: 100%; /* Make the image full width */
    border-radius: 12px;
    text-decoration: none;
    color: white;
    font-size: 1.2em;
  }

  .secondaryScreenShotContainer.MuiBox-root {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: 32px;
    width: 200px;
  }


  .secondaryScreenShot {
    border-radius: 12px;
    text-decoration: none;
    font-size: 1.2em;
    width: 200px;
  }


  .tertiaryScreenShotContainer.MuiBox-root  {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border-radius: 32px;
    width: auto; 
    margin-top: 12px;
}

.iconsContainer {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens if needed */
    justify-content: center; /* Center the images */
    gap: 50px; /* Add spacing between images */
}


  .tertiaryScreenShot {
    border-radius: 12px;
    text-decoration: none;
    font-size: 1.2em;
    width: 100px;
  }
  
  
  .appDescription.MuiBox-root {
    color: white;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: bold;
    max-width: 400px;
    min-height: 140px;
    margin-top: 24px;
    font-size: 1.2em;
    align-self: center;
  }

  .pro-buttons.MuiGrid-root {
    margin-top: 24px;
  }

  .bullets.MuiBox-root {
    align-content: flex-start;
    width: 100%;
    text-align: center;
    max-width: 500px;
  }

  .card-image {
    align-self: center;
    height: 355px;
    width: 200px;
    border-radius: 16px;
  }

  .news-card-image {
    width: 100%; /* Allow responsive width within container */
    max-width: 100px; /* Maximum width of 50px */
    height: auto; /* Maintain aspect ratio */
    border-radius: 16px;
  }

  .socials-card-image {
    width: 100%; /* Allow responsive width within container */
    max-width: 200px; /* Maximum width of 50px */
    height: 75px;
    border-radius: 16px;
  }

  .link-container.MuiBox-root { 
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center 
  }

  .parentContainer.MuiBox-root {
      display: flex;
      align-items: center;
      flex-direction: row; /* Row on larger screens */
      justify-content: center;
      width: auto;
      margin-top: 12px;
  }
  
  .parentBox.MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 75%;
  }
  
  .parentTitle.MuiTypography-root {
      font-size: 1.5rem;
  }
  
  .parentDescription.MuiTypography-root {
      font-size: 1rem;
      color: #ccc;
      margin-bottom: 12px;
  }

  .parentImage {
    width: "auto";
    max-width: 250px;
  }