  .bullet {
    display: flex;
    align-self: center;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 12px;
  }
  
  .bulletIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
   
  }
  
  .bulletIconContainer.withBackground {
    background-color: #ccc; /* Example for a custom background color */
  }
  
  .bulletIcon {
    font-size: 14px;
    color: black;
  }
  
  .checkIcon {
    font-size: 20px;
    color: #76A9E7;
  }
  
  .sectionDescription {
    font-weight: normal;
    color: #ccc; /* Default text color */
  }
  
  .sectionDescriptionBold {
    font-weight: bold;
    color: #ccc;
  }

