.storyScreen.MuiBox-root {
  background-color: #131212;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.storyContent.MuiBox-root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.storyImage {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  aspect-ratio: 9/16;
}

.audioControls.MuiBox-root {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.imageContainer.MuiBox-root.showControls .audioControls.MuiBox-root {
  display: block;
}

.storyDetails.MuiBox-root {
  margin-top: 20px;
  width: 100%;
  max-width: 512px;
}

.navigationButtons.MuiBox-root {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
}

.chapter-info.MuiBox-root {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
}

.imageContainer.MuiBox-root.showControls .chapter-info.MuiBox-root {
  display: block;
}

.imageContainer.MuiBox-root.showControls .chapterTextFieldContainer.MuiBox-root {
  display: block;
}

.imageContainer.MuiBox-root.showControls .chapterPrevious.MuiBox-root {
  display: block;
}

.imageContainer.MuiBox-root.showControls .chapterNext.MuiBox-root {
  display: block;
}

.chapterTextFieldContainer.MuiBox-root {
  display: none;
  background-color: transparent;
  position: absolute !important;
  z-index: 10 !important;
  align-self: flex-end;
  border-radius: 5px;
}

.chapterPrevious.MuiBox-root {
  display: none;
  background-color: transparent;
  position: absolute !important;
  z-index: 10 !important;
  border-radius: 5px;
}

.chapterNext.MuiBox-root {
  display: none;
  background-color: transparent;
  position: absolute !important;
  z-index: 10 !important;
  border-radius: 5px;
}

.chapterTextField {
  background-color: transparent;
  font-size: 14 !important;
  color: white;
}

.navigation-arrow.MuiBox-root {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  display: none;
}

.imageContainer.MuiBox-root.showControls .navigation-arrow.MuiBox-root {
  display: block;
}

.navigation-arrow.left.MuiBox-root {
  left: 10px;
}

.navigation-arrow.right.MuiBox-root {
  right: 10px;
}

.loadingScreen.MuiBox-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
  font-size: 1.5em;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid orange;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.buttonContainer.MuiBox-root {
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
}

.downloadButton.MuiButton-root {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  color: white;
  font-size: 1em;
  transition: background-color 0.3s;
}

.downloadButton.MuiButton-root img {
  margin-right: 16px;
  width: 28px;
}

.android.MuiButton-root {
  background-color: white;
  color: black;
}

.ios.MuiButton-root {
  background-color: white;
  color: black;
}

.android.MuiButton-root:hover {
  background-color: #ccc;
}

.ios.MuiButton-root:hover {
  background-color: #ccc;
}

.errorPage.MuiBox-root {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensure it takes the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.errorImage.MuiBox-root {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.errorText.MuiTypography-root {
  font-size: 24px;
  color: white; /* Optional: Add a background to make the text stand out */
  border-radius: 8px;
  z-index: 10;
}

.homeButton.MuiButton-root {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: white;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.homeButton.MuiButton-root:hover {
  background-color: #0056b3;
}

.container.MuiBox-root  {
  width: 100%;
  height: 100%;
}

.imageWrapper.MuiBox-root  {
  position: relative;
  width: 56.25vh;
  height: 100vh;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay.MuiBox-root  {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 4;
  background-color: white;
  bottom: 0;
}

.overlayBox.MuiBox-root {
  position: absolute;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}